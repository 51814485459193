// Testing server connections

// export const base_url = "http://180.149.241.128:3001/";
// export const broker_url = "http://180.149.241.128:3001/";
// export const broker_redirect_url = "http://180.149.241.128:3001/";
// export const react_domain = "http://180.149.241.128:3000/";
// export const panel_name = "smartalgo";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

//Live Connections Smart algo

// export const base_url = "https://api.smartalgo.in:3001/";
// export const broker_url = "https://api.smartalgo.in:3001/";
// export const broker_redirect_url = "https://api.smartalgo.in:3001/";
// export const react_domain = "https://test.smartalgo.in/";
// export const panel_name = "smartalgo";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

//Live Connection Quick Algo

// export const base_url = "https://client.quickalgoplus.in:3004/";
// export const broker_url = "https://client.quickalgoplus.in:3004/";
// export const broker_redirect_url = "https://client.quickalgoplus.in:3004/";
// export const react_domain = "https://client.quickalgoplus.in/";
// export const panel_name = "quickalgo";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections infinite-intelligence

// export const base_url = "https://software.infinite-intelligence.co.in:3006/";
// export const broker_url = "https://software.infinite-intelligence.co.in:3006/";
// export const broker_redirect_url = "https://software.infinite-intelligence.co.in:3006/";
// export const react_domain = "https://software.infinite-intelligence.co.in/";
// export const panel_name = "infiniteintelligence";
// export const broker_signal_url = "https://brokersignal.infinite-intelligence.co.in/broker-signals";

// Live Connections Kashi Algo

// export const base_url = "https://software.kashialgo.com:3007/";
// export const broker_url = "https://software.kashialgo.com:3007/";
// export const broker_redirect_url = "https://software.kashialgo.com:3007/";
// export const react_domain = "https://software.kashialgo.com/";
// export const panel_name = "kashialgo";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections tradesofttechnology

// export const base_url = "https://software.tradesofttechnology.com:3008/";
// export const broker_url = "https://software.tradesofttechnology.com:3008/";
// export const broker_redirect_url = "https://software.tradesofttechnology.com:3008/";
// export const react_domain = "https://software.tradesofttechnology.com/";
// export const panel_name = "tradesofttechnology";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections nextalgo

// export const base_url = "https://app.nextalgo.net:3010/";
// export const broker_url = "https://app.nextalgo.net:3010/";
// export const broker_redirect_url = "https://app.nextalgo.net:3010/";
// export const react_domain = "https://app.nextalgo.net/";
// export const panel_name = "nextalgo";
// export const broker_signal_url = "https://brokersignal.nextalgo.net/broker-signals";

// Live Connections winningturtle

// export const base_url = "https://software.winningturtle.co.in:3011/";
// export const broker_url = "https://software.winningturtle.co.in:3011/";
// export const broker_redirect_url = "https://software.winningturtle.co.in:3011/";
// export const react_domain = "https://software.winningturtle.co.in/";
// export const panel_name = "winningturtle";
// export const broker_signal_url = "https://brokersignal.winningturtle.in/broker-signals";

// Live Connections skyiqinfotech

// export const base_url = "https://software.skyiqinfotech.com:3012/";
// export const broker_url = "https://software.skyiqinfotech.com:3012/";
// export const broker_redirect_url = "https://software.skyiqinfotech.com:3012/";
// export const react_domain = "https://software.skyiqinfotech.com/";
// export const panel_name = "skyiqinfotech";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";


// Live Connections digitalalgotech

// export const base_url = "https://software.digitalalgotech.com:3014/";
// export const broker_url = "https://software.digitalalgotech.com:3014/";
// export const broker_redirect_url = "https://software.digitalalgotech.com:3014/";
// export const react_domain = "https://software.digitalalgotech.com/";
// export const panel_name = "digitalalgotech";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections expertalgo

// export const base_url = "https://software.expertalgo.com:3015/";
// export const broker_url = "https://software.expertalgo.com:3015/";
// export const broker_redirect_url = "https://software.expertalgo.com:3015/";
// export const react_domain = "https://software.expertalgo.com/";
// export const panel_name = "expertalgo";
// export const broker_signal_url = "https://brokersignal.expertalgo.com/broker-signals";

// Live Connections roboitsolution

// export const base_url = "https://software.roboitsolution.com:3016/";
// export const broker_url = "https://software.roboitsolution.com:3016/";
// export const broker_redirect_url = "https://software.roboitsolution.com:3016/";
// export const react_domain = "https://software.roboitsolution.com/";
// export const panel_name = "roboitsolution";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections growtechitsolutions

// export const base_url = "https://client.growtechitsolutions.com:3017/";
// export const broker_url = "https://client.growtechitsolutions.com:3017/";
// export const broker_redirect_url = "https://client.growtechitsolutions.com:3017/";
// export const react_domain = "https://client.growtechitsolutions.com/";
// export const panel_name = "growtechitsolutions";
// export const broker_signal_url = "https://brokersignal.growtechitsolutions.com/broker-signals";

// Live Connections spideralgo

// export const base_url = "https://software.spideralgo.com:3019/";
// export const broker_url = "https://software.spideralgo.com:3019/";
// export const broker_redirect_url = "https://software.spideralgo.com:3019/";
// export const react_domain = "https://software.spideralgo.com/";
// export const panel_name = "spideralgo";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections algobuzz

// export const base_url = "https://software.algobuzz.in:3021/";
// export const broker_url = "https://software.algobuzz.in:3021/";
// export const broker_redirect_url = "https://software.algobuzz.in:3021/";
// export const react_domain = "https://software.algobuzz.in/";
// export const panel_name = "algobuzz";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections algomaster

// export const base_url = "https://client.algomaster.in:3024/";
// export const broker_url = "https://client.algomaster.in:3024/";
// export const broker_redirect_url = "https://client.algomaster.in:3024/";
// export const react_domain = "https://client.algomaster.in/";
// export const panel_name = "algomaster";
// export const broker_signal_url = "https://brokersignal.algomaster.in/broker-signals";

// Live Connections etechalgo

// export const base_url = "https://software.etechalgo.com:3026/";
// export const broker_url = "https://software.etechalgo.com:3026/";
// export const broker_redirect_url = "https://software.etechalgo.com:3026/";
// export const react_domain = "https://software.etechalgo.com/";
// export const panel_name = "etechalgo";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections a1advanceinfotech

// export const base_url = "https://software.a1advanceinfotech.com:3027/";
// export const broker_url = "https://software.a1advanceinfotech.com:3027/";
// export const broker_redirect_url = "https://software.a1advanceinfotech.com:3027/";
// export const react_domain = "https://software.a1advanceinfotech.com/";
// export const panel_name = "a1advanceinfotech";
// export const broker_signal_url = "https://brokersignal.a1advanceinfotech.com/broker-signals";

// Live Connections indiaalgo

export const base_url = "https://software.indiaalgo.com:3028/";
export const broker_url = "https://software.indiaalgo.com:3028/";
export const broker_redirect_url = "https://software.indiaalgo.com:3028/";
export const react_domain = "https://software.indiaalgo.com/";
export const panel_name = "indiaalgo";
export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections infiniteinvestments

// export const base_url = "https://software.infiniteinvestments.co.in:3031/";
// export const broker_url = "https://software.infiniteinvestments.co.in:3031/";
// export const broker_redirect_url = "https://software.infiniteinvestments.co.in:3031/";
// export const react_domain = "https://software.infiniteinvestments.co.in/";
// export const panel_name = "infiniteinvestments";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections lnpalgosoftware

// export const base_url = "https://software.lnpalgosoftware.in:3032/";
// export const broker_url = "https://software.lnpalgosoftware.in:3032/";
// export const broker_redirect_url = "https://software.lnpalgosoftware.in:3032/";
// export const react_domain = "https://software.lnpalgosoftware.in/";
// export const panel_name = "lnpalgosoftware";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections chartology

// export const base_url = "https://software.chartology.in:3033/";
// export const broker_url = "https://software.chartology.in:3033/";
// export const broker_redirect_url = "https://software.chartology.in:3033/";
// export const react_domain = "https://software.chartology.in/";
// export const panel_name = "chartology";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections betasoftinfotech

// export const base_url = "https://client.betasoftinfotech.com:3034/";
// export const broker_url = "https://client.betasoftinfotech.com:3034/";
// export const broker_redirect_url = "https://client.betasoftinfotech.com:3034/";
// export const react_domain = "https://client.betasoftinfotech.com/";
// export const panel_name = "betasoftinfotech";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections adroitalgorithms

// export const base_url = "https://software.adroitalgorithms.com:3035/";
// export const broker_url = "https://software.adroitalgorithms.com:3035/";
// export const broker_redirect_url = "https://software.adroitalgorithms.com:3035/";
// export const react_domain = "https://software.adroitalgorithms.com/";
// export const panel_name = "adroitalgorithms";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections skyalgo

// export const base_url = "https://software.skyalgo.in:3036/";
// export const broker_url = "https://software.skyalgo.in:3036/";
// export const broker_redirect_url = "https://software.skyalgo.in:3036/";
// export const react_domain = "https://software.skyalgo.in/";
// export const panel_name = "skyalgo";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections algotrade360

// export const base_url = "https://software.algotrade360.com:3037/";
// export const broker_url = "https://software.algotrade360.com:3037/";
// export const broker_redirect_url = "https://software.algotrade360.com:3037/";
// export const react_domain = "https://software.algotrade360.com/";
// export const panel_name = "algotrade360";
// export const broker_signal_url = "https://brokersignal.algotrade360.com/broker-signals";

// Live Connections adamassolution

// export const base_url = "https://software.adamassolution.com:3040/";
// export const broker_url = "https://software.adamassolution.com:3040/";
// export const broker_redirect_url = "https://software.adamassolution.com:3040/";
// export const react_domain = "https://software.adamassolution.com/";
// export const panel_name = "adamassolution";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections algoitech

// export const base_url = "https://software.algoitech.com:3041/";
// export const broker_url = "https://software.algoitech.com:3041/";
// export const broker_redirect_url = "https://software.algoitech.com:3041/";
// export const react_domain = "https://software.algoitech.com/";
// export const panel_name = "algoitech";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections mtxacademy

// export const base_url = "https://software.mtxacademy.com:3042/";
// export const broker_url = "https://software.mtxacademy.com:3042/";
// export const broker_redirect_url = "https://software.mtxacademy.com:3042/";
// export const react_domain = "https://software.mtxacademy.com/";
// export const panel_name = "mtxacademy";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections adonomist

// export const base_url = "https://software.adonomist.com:3044/";
// export const broker_url = "https://software.adonomist.com:3044/";
// export const broker_redirect_url = "https://software.adonomist.com:3044/";
// export const react_domain = "https://software.adonomist.com/";
// export const panel_name = "adonomist";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections goalgos

// export const base_url = "https://software.goalgos.com:3047/";
// export const broker_url = "https://software.goalgos.com:3047/";
// export const broker_redirect_url = "https://software.goalgos.com:3047/";
// export const react_domain = "https://software.goalgos.com/";
// export const panel_name = "goalgos";
// export const broker_signal_url = "https://brokersignal.goalgos.com/broker-signals";

// Live Connections heshtech

// export const base_url = "https://software.heshtech.co.in:3049/";
// export const broker_url = "https://software.heshtech.co.in:3049/";
// export const broker_redirect_url = "https://software.heshtech.co.in:3049/";
// export const react_domain = "https://software.heshtech.co.in/";
// export const panel_name = "heshtech";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections dzirealgo

// export const base_url = "https://software.dzirealgo.com:3050/";
// export const broker_url = "https://software.dzirealgo.com:3050/";
// export const broker_redirect_url = "https://software.dzirealgo.com:3050/";
// export const react_domain = "https://software.dzirealgo.com/";
// export const panel_name = "dzirealgo";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections advancetechnos

// export const base_url = "https://software.advancetechnos.com:3051/";
// export const broker_url = "https://software.advancetechnos.com:3051/";
// export const broker_redirect_url = "https://software.advancetechnos.com:3051/";
// export const react_domain = "https://software.advancetechnos.com/";
// export const panel_name = "advancetechnos";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections tradealpha

// export const base_url = "https://software.tradealpha.in:3052/";
// export const broker_url = "https://software.tradealpha.in:3052/";
// export const broker_redirect_url = "https://software.tradealpha.in:3052/";
// export const react_domain = "https://software.tradealpha.in/";
// export const panel_name = "tradealpha";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections primaxsolution

// export const base_url = "https://software.primaxsolution.com:3055/";
// export const broker_url = "https://software.primaxsolution.com:3055/";
// export const broker_redirect_url = "https://software.primaxsolution.com:3055/";
// export const react_domain = "https://software.primaxsolution.com/";
// export const panel_name = "primaxsolution";
// export const broker_signal_url = "https://brokersignal.primaxsolution.com/broker-signals";

// Live Connections simpletrade

// export const base_url = "https://software.simpletrade.in:3058/";
// export const broker_url = "https://software.simpletrade.in:3058/";
// export const broker_redirect_url = "https://software.simpletrade.in:3058/";
// export const react_domain = "https://software.simpletrade.in/";
// export const panel_name = "simpletrade";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections redalgo

// export const base_url = "https://software.redalgo.in:3059/";
// export const broker_url = "https://software.redalgo.in:3059/";
// export const broker_redirect_url = "https://software.redalgo.in:3059/";
// export const react_domain = "https://software.redalgo.in/";
// export const panel_name = "redalgo";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections roboticalgo

// export const base_url = "https://client.roboticalgo.com:3060/";
// export const broker_url = "https://client.roboticalgo.com:3060/";
// export const broker_redirect_url = "https://client.roboticalgo.com:3060/";
// export const react_domain = "https://client.roboticalgo.com/";
// export const panel_name = "roboticalgo";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections brightextech

// export const base_url = "https://software.brightextech.com:3062/";
// export const broker_url = "https://software.brightextech.com:3062/";
// export const broker_redirect_url = "https://software.brightextech.com:3062/";
// export const react_domain = "https://software.brightextech.com/";
// export const panel_name = "brightextech";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections richway

// export const base_url = "https://software.richway.tech:3065/";
// export const broker_url = "https://software.richway.tech:3065/";
// export const broker_redirect_url = "https://software.richway.tech:3065/";
// export const react_domain = "https://software.richway.tech/";
// export const panel_name = "richway";
// export const broker_signal_url = "https://brokersignal.richway.tech/broker-signals";

// Live Connections svalgo

// export const base_url = "https://software.svalgo.com:3066/";
// export const broker_url = "https://software.svalgo.com:3066/";
// export const broker_redirect_url = "https://software.svalgo.com:3066/";
// export const react_domain = "https://software.svalgo.com/";
// export const panel_name = "svalgo";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections myriadtechnology

// export const base_url = "https://software.myriadtechnology.in:3063/";
// export const broker_url = "https://software.myriadtechnology.in:3063/";
// export const broker_redirect_url = "https://software.myriadtechnology.in:3063/";
// export const react_domain = "https://software.myriadtechnology.in/";
// export const panel_name = "myriadtechnology";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections myriadtechnology

// export const base_url = "https://mastertrust.node.smartalgo.in/";
// export const broker_url = "https://mastertrust.node.smartalgo.in/";
// export const broker_redirect_url = "https://mastertrust.node.smartalgo.in/";
// export const react_domain = "https://mastertrust.smartalgo.in";
// export const panel_name = "mastertrust.smartalgo";
//export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections adonomist

// export const base_url = "https://mastertrust.node.adonomist.com/";
// export const broker_url = "https://mastertrust.node.adonomist.com/";
// export const broker_redirect_url = "https://mastertrust.node.adonomist.com/";
// export const react_domain = "https://mastertrust.adonomist.com";
// export const panel_name = "mastertrust.adonomist";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections vedalgoinfotech

// export const base_url = "https://software.vedalgoinfotech.com:3069/";
// export const broker_url = "https://software.vedalgoinfotech.com:3069/";
// export const broker_redirect_url = "https://software.vedalgoinfotech.com:3069/";
// export const react_domain = "https://software.vedalgoinfotech.com";
// export const panel_name = "vedalgoinfotech";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections thinknextitsolution

// export const base_url = "https://software.thinknextitsolution.com:3070/";
// export const broker_url = "https://software.thinknextitsolution.com:3070/";
// export const broker_redirect_url = "https://software.thinknextitsolution.com:3070/";
// export const react_domain = "https://software.thinknextitsolution.com/";
// export const panel_name = "thinknextitsolution";
// export const broker_signal_url = "https://brokersignal.thinknextitsolution.com/broker-signals";

// Live Connections finvachi

// export const base_url = "https://software.finvachi.com:3071/";
// export const broker_url = "https://software.finvachi.com:3071/";
// export const broker_redirect_url = "https://software.finvachi.com:3071/";
// export const react_domain = "https://software.finvachi.com/";
// export const panel_name = "finvachi";
// export const broker_signal_url = "https://brokersignal.finvachi.com/broker-signals";

// Live Connections arithmeticalsolutions

// export const base_url = "https://software.arithmeticalsolutions.com:3073/";
// export const broker_url = "https://software.arithmeticalsolutions.com:3073/";
// export const broker_redirect_url = "https://software.arithmeticalsolutions.com:3073/";
// export const react_domain = "https://software.arithmeticalsolutions.com/";
// export const panel_name = "arithmeticalsolutions";
//export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections phoenixinfo

// export const base_url = "https://software.phoenixinfo.in:3075/";
// export const broker_url = "https://software.phoenixinfo.in:3075/";
// export const broker_redirect_url = "https://software.phoenixinfo.in:3075/";
// export const react_domain = "https://software.phoenixinfo.in/";
// export const panel_name = "phoenixinfo";
//export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections suryaintelligence

// export const base_url = "https://software.suryaintelligence.com:3076/";
// export const broker_url = "https://software.suryaintelligence.com:3076/";
// export const broker_redirect_url = "https://software.suryaintelligence.com:3076/";
// export const react_domain = "https://software.suryaintelligence.com/";
// export const panel_name = "suryaintelligence";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections o2softech

// export const base_url = "https://software.o2softech.com:3078/";
// export const broker_url = "https://software.o2softech.com:3078/";
// export const broker_redirect_url = "https://software.o2softech.com:3078/";
// export const react_domain = "https://software.o2softech.com/";
// export const panel_name = "o2softech";
// export const broker_signal_url = "https://brokersignal.o2softech.com/broker-signals";

// Live Connections aptechalgo

// export const base_url = "https://software.aptechalgo.com:3079/";
// export const broker_url = "https://software.aptechalgo.com:3079/";
// export const broker_redirect_url = "https://software.aptechalgo.com:3079/";
// export const react_domain = "https://software.aptechalgo.com/";
// export const panel_name = "aptechalgo";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections alpstine Saparate New

// export const base_url = "https://software.alpstine.com:3080/";
// export const broker_url = "https://software.alpstine.com:3080/";
// export const broker_redirect_url = "https://software.alpstine.com:3080/";
// export const react_domain = "https://software.alpstine.com/";
// export const panel_name = "alpstine";
// export const broker_signal_url = "https://brokersignal.alpstine.com/broker-signals";

// Live Connections algostarplus

// export const base_url = "https://software.algostarplus.com:3081/";
// export const broker_url = "https://software.algostarplus.com:3081/";
// export const broker_redirect_url = "https://software.algostarplus.com:3081/";
// export const react_domain = "https://software.algostarplus.com/";
// export const panel_name = "algostarplus";
//export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections shinesoftech

// export const base_url = "https://software.shinesoftech.in:3083/";
// export const broker_url = "https://software.shinesoftech.in:3083/";
// export const broker_redirect_url = "https://software.shinesoftech.in:3083/";
// export const react_domain = "https://software.shinesoftech.in/";
// export const panel_name = "shinesoftech";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections digitechalgo

// export const base_url = "https://software.digitechalgo.com:3084/";
// export const broker_url = "https://software.digitechalgo.com:3084/";
// export const broker_redirect_url = "https://software.digitechalgo.com:3084/";
// export const react_domain = "https://software.digitechalgo.com/";
// export const panel_name = "digitechalgo";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections securealgo

// export const base_url = "https://software.securealgo.com:3085/";
// export const broker_url = "https://software.securealgo.com:3085/";
// export const broker_redirect_url = "https://software.securealgo.com:3085/";
// export const react_domain = "https://software.securealgo.com/";
// export const panel_name = "securealgo";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections adroitalgo

// export const base_url = "https://software.adroitalgo.com:3089/";
// export const broker_url = "https://software.adroitalgo.com:3089/";
// export const broker_redirect_url = "https://software.adroitalgo.com:3089/";
// export const react_domain = "https://software.adroitalgo.com/";
// export const panel_name = "adroitalgo";
//export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections websysinfotechsolution

// export const base_url = "https://software.websysinfotechsolution.in:3090/";
// export const broker_url = "https://software.websysinfotechsolution.in:3090/";
// export const broker_redirect_url = "https://software.websysinfotechsolution.in:3090/";
// export const react_domain = "https://software.websysinfotechsolution.in/";
// export const panel_name = "websysinfotechsolution";
//export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections algoapex

// export const base_url = "https://software.algoapex.in:3091/";
// export const broker_url = "https://software.algoapex.in:3091/";
// export const broker_redirect_url = "https://software.algoapex.in:3091/";
// export const react_domain = "https://software.algoapex.in/";
// export const panel_name = "algoapex";
//export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections parashvnathtechnologies

// export const base_url = "https://software.parashvnathtechnologies.com:3092/";
// export const broker_url = "https://software.parashvnathtechnologies.com:3092/";
// export const broker_redirect_url = "https://software.parashvnathtechnologies.com:3092/";
// export const react_domain = "https://software.parashvnathtechnologies.com/";
// export const panel_name = "parashvnathtechnologies";
//export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections stoplesstechnology

// export const base_url = "https://software.stoplesstechnology.com:3093/";
// export const broker_url = "https://software.stoplesstechnology.com:3093/";
// export const broker_redirect_url = "https://software.stoplesstechnology.com:3093/";
// export const react_domain = "https://software.stoplesstechnology.com/";
// export const panel_name = "stoplesstechnology";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections algobase

// export const base_url = "https://software.algobase.in:3095/";
// export const broker_url = "https://software.algobase.in:3095/";
// export const broker_redirect_url = "https://software.algobase.in:3095/";
// export const react_domain = "https://software.algobase.in/";
// export const panel_name = "algobase";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections surakshaalgo

// export const base_url = "https://software.surakshaalgo.com:3096/";
// export const broker_url = "https://software.surakshaalgo.com:3096/";
// export const broker_redirect_url = "https://software.surakshaalgo.com:3096/";
// export const react_domain = "https://software.surakshaalgo.com/";
// export const panel_name = "surakshaalgo";
//export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections technoshree

// export const base_url = "https://software.technoshree.com:3097/";
// export const broker_url = "https://software.technoshree.com:3097/";
// export const broker_redirect_url = "https://software.technoshree.com:3097/";
// export const react_domain = "https://software.technoshree.com/";
// export const panel_name = "technoshree";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections pandpinfotech

// export const base_url = "https://software.pandpinfotech.com:3098/";
// export const broker_url = "https://software.pandpinfotech.com:3098/";
// export const broker_redirect_url = "https://software.pandpinfotech.com:3098/";
// export const react_domain = "https://software.pandpinfotech.com/";
// export const panel_name = "pandpinfotech";
//export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections algovertex

// export const base_url = "https://software.algovertex.com:3099/";
// export const broker_url = "https://software.algovertex.com:3099/";
// export const broker_redirect_url = "https://software.algovertex.com:3099/";
// export const react_domain = "https://software.algovertex.com/";
// export const panel_name = "algovertex";
//export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections indiratrade.smartalgo

// export const base_url = "https://indiratrade.smartalgo.in:3100/";
// export const broker_url = "https://indiratrade.smartalgo.in:3100/";
// export const broker_redirect_url = "https://indiratrade.smartalgo.in:3100/";
// export const react_domain = "https://indiratrade.smartalgo.in/";
// export const panel_name = "indiratrade";
//export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections wealthepic

// export const base_url = "https://software.wealthepic.in:3101/";
// export const broker_url = "https://software.wealthepic.in:3101/";
// export const broker_redirect_url = "https://software.wealthepic.in:3101/";
// export const react_domain = "https://software.wealthepic.in/";
// export const panel_name = "wealthepic";
//export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections braintechintelligence

// export const base_url = "https://software.braintechintelligence.com:3102/";
// export const broker_url = "https://software.braintechintelligence.com:3102/";
// export const broker_redirect_url = "https://software.braintechintelligence.com:3102/";
// export const react_domain = "https://software.braintechintelligence.com/";
// export const panel_name = "braintechintelligence";
//export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections goproinfotech

// export const base_url = "https://software.goproinfotech.com:3103/";
// export const broker_url = "https://software.goproinfotech.com:3103/";
// export const broker_redirect_url = "https://software.goproinfotech.com:3103/";
// export const react_domain = "https://software.goproinfotech.com/";
// export const panel_name = "goproinfotech";
//export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections adonomist

// export const base_url = "https://trade.adonomist.com:3103/";
// export const broker_url = "https://trade.adonomist.com:3103/";
// export const broker_redirect_url = "https://trade.adonomist.com:3103/";
// export const react_domain = "https://trade.adonomist.com/";
// export const panel_name = "adonomist";
// export const broker_signal_url = "https://brokersignal.adonomist.com/broker-signals";

// Live Connections equitypandit

// export const base_url = "https://wealth.equitypandit.co.in:3104/";
// export const broker_url = "https://wealth.equitypandit.co.in:3104/";
// export const broker_redirect_url = "https://wealth.equitypandit.co.in:3104/";
// export const react_domain = "https://wealth.equitypandit.co.in/";
// export const panel_name = "equitypandit";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections higherinfotech

// export const base_url = "https://software.higherinfotech.com:3105/";
// export const broker_url = "https://software.higherinfotech.com:3105/";
// export const broker_redirect_url = "https://software.higherinfotech.com:3105/";
// export const react_domain = "https://software.higherinfotech.com/";
// export const panel_name = "higherinfotech";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections welgoinfotech

// export const base_url = "https://software.welgoinfotech.in:3106/";
// export const broker_url = "https://software.welgoinfotech.in:3106/";
// export const broker_redirect_url = "https://software.welgoinfotech.in:3106/";
// export const react_domain = "https://software.welgoinfotech.in/";
// export const panel_name = "welgoinfotech";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections robotrustinfotech

// export const base_url = "https://software.robotrustinfotech.com:3107/";
// export const broker_url = "https://software.robotrustinfotech.com:3107/";
// export const broker_redirect_url = "https://software.robotrustinfotech.com:3107/";
// export const react_domain = "https://software.robotrustinfotech.com/";
// export const panel_name = "robotrustinfotech";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections devineinfo

// export const base_url = "https://software.devineinfo.tech:3108/";
// export const broker_url = "https://software.devineinfo.tech:3108/";
// export const broker_redirect_url = "https://software.devineinfo.tech:3108/";
// export const react_domain = "https://software.devineinfo.tech/";
// export const panel_name = "devineinfo";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections firstalgosolutions

// export const base_url = "https://software.firstalgosolutions.com:3109/";
// export const broker_url = "https://software.firstalgosolutions.com:3109/";
// export const broker_redirect_url = "https://software.firstalgosolutions.com:3109/";
// export const react_domain = "https://software.firstalgosolutions.com/";
// export const panel_name = "firstalgosolutions";
//export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections nextbrand

// export const base_url = "https://software.nextbrand.co.in:3110/";
// export const broker_url = "https://software.nextbrand.co.in:3110/";
// export const broker_redirect_url = "https://software.nextbrand.co.in:3110/";
// export const react_domain = "https://software.nextbrand.co.in/";
// export const panel_name = "nextbrand";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections wingsalgo

// export const base_url = "https://software.wingsalgo.com:3111/";
// export const broker_url = "https://software.wingsalgo.com:3111/";
// export const broker_redirect_url = "https://software.wingsalgo.com:3111/";
// export const react_domain = "https://software.wingsalgo.com/";
// export const panel_name = "wingsalgo";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections algo

// export const base_url = "https://software.algo.org.in:3112/";
// export const broker_url = "https://software.algo.org.in:3112/";
// export const broker_redirect_url = "https://software.algo.org.in:3112/";
// export const react_domain = "https://software.algo.org.in/";
// export const panel_name = "algo";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections honesttechnology

// export const base_url = "https://software.honesttechnology.co.in:3113/";
// export const broker_url = "https://software.honesttechnology.co.in:3113/";
// export const broker_redirect_url = "https://software.honesttechnology.co.in:3113/";
// export const react_domain = "https://software.honesttechnology.co.in/";
// export const panel_name = "honesttechnology";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections implementnewtechnologies

// export const base_url = "https://software.implementnewtechnologies.com:3114/";
// export const broker_url = "https://software.implementnewtechnologies.com:3114/";
// export const broker_redirect_url = "https://software.implementnewtechnologies.com:3114/";
// export const react_domain = "https://software.implementnewtechnologies.com/";
// export const panel_name = "implementnewtechnologies";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections inalgoeai

// export const base_url = "https://software.inalgoeai.com:3115/";
// export const broker_url = "https://software.inalgoeai.com:3115/";
// export const broker_redirect_url = "https://software.inalgoeai.com:3115/";
// export const react_domain = "https://software.inalgoeai.com/";
// export const panel_name = "inalgoeai";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections tradegoexpert

// export const base_url = "https://software.tradegoexpert.com:3116/";
// export const broker_url = "https://software.tradegoexpert.com:3116/";
// export const broker_redirect_url = "https://software.tradegoexpert.com:3116/";
// export const react_domain = "https://software.tradegoexpert.com/";
// export const panel_name = "tradegoexpert";
//export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections starinfotechitsolutions

// export const base_url = "https://software.starinfotechitsolutions.com:3117/";
// export const broker_url = "https://software.starinfotechitsolutions.com:3117/";
// export const broker_redirect_url = "https://software.starinfotechitsolutions.com:3117/";
// export const react_domain = "https://software.starinfotechitsolutions.com/";
// export const panel_name = "starinfotechitsolutions";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections techfires

// export const base_url = "https://software.techfires.net:3119/";
// export const broker_url = "https://software.techfires.net:3119/";
// export const broker_redirect_url = "https://software.techfires.net:3119/";
// export const react_domain = "https://software.techfires.net/";
// export const panel_name = "techfires";
// export const broker_signal_url = "https://brokersignal.techfires.net/broker-signals";

// Live Connections tradewala

// export const base_url = "https://software.tradewala.com:3120/";
// export const broker_url = "https://software.tradewala.com:3120/";
// export const broker_redirect_url = "https://software.tradewala.com:3120/";
// export const react_domain = "https://software.tradewala.com/";
// export const panel_name = "tradewala";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections aandginfotech

// export const base_url = "https://software.aandginfotech.com:3121/";
// export const broker_url = "https://software.aandginfotech.com:3121/";
// export const broker_redirect_url = "https://software.aandginfotech.com:3121/";
// export const react_domain = "https://software.aandginfotech.com/";
// export const panel_name = "aandginfotech";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections itminds

// export const base_url = "https://software.itminds.co.in:3123/";
// export const broker_url = "https://software.itminds.co.in:3123/";
// export const broker_redirect_url = "https://software.itminds.co.in:3123/";
// export const react_domain = "https://software.itminds.co.in/";
// export const panel_name = "itminds";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections candlemantra

// export const base_url = "https://software.candlemantra.com:3124/";
// export const broker_url = "https://software.candlemantra.com:3124/";
// export const broker_redirect_url = "https://software.candlemantra.com:3124/";
// export const react_domain = "https://software.candlemantra.com/";
// export const panel_name = "candlemantra";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections smarttechalgo

// export const base_url = "https://software.smarttechalgo.com:3125/";
// export const broker_url = "https://software.smarttechalgo.com:3125/";
// export const broker_redirect_url = "https://software.smarttechalgo.com:3125/";
// export const react_domain = "https://software.smarttechalgo.com/";
// export const panel_name = "smarttechalgo";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections beefortune

// export const base_url = "https://software.beefortune.com:3126/";
// export const broker_url = "https://software.beefortune.com:3126/";
// export const broker_redirect_url = "https://software.beefortune.com:3126/";
// export const react_domain = "https://software.beefortune.com/";
// export const panel_name = "beefortune";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections robotechpro

// export const base_url = "https://software.robotechpro.com:3127/";
// export const broker_url = "https://software.robotechpro.com:3127/";
// export const broker_redirect_url = "https://software.robotechpro.com:3127/";
// export const react_domain = "https://software.robotechpro.com/";
// export const panel_name = "robotechpro";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections sharpalgo

// export const base_url = "https://software.sharpalgo.com:3128/";
// export const broker_url = "https://software.sharpalgo.com:3128/";
// export const broker_redirect_url = "https://software.sharpalgo.com:3128/";
// export const react_domain = "https://software.sharpalgo.com/";
// export const panel_name = "sharpalgo";
// export const broker_signal_url = "https://brokersignal.sharpalgo.com/broker-signals";

// Live Connections infiniteitsolution

// export const base_url = "https://software.infiniteitsolution.com:3129/";
// export const broker_url = "https://software.infiniteitsolution.com:3129/";
// export const broker_redirect_url = "https://software.infiniteitsolution.com:3129/";
// export const react_domain = "https://software.infiniteitsolution.com/";
// export const panel_name = "infiniteitsolution";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections alpinealgo

// export const base_url = "https://software.alpinealgo.com:3130/";
// export const broker_url = "https://software.alpinealgo.com:3130/";
// export const broker_redirect_url = "https://software.alpinealgo.com:3130/";
// export const react_domain = "https://software.alpinealgo.com/";
// export const panel_name = "alpinealgo";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections joyinfotech

// export const base_url = "https://software.joyinfotech.co.in:3131/";
// export const broker_url = "https://software.joyinfotech.co.in:3131/";
// export const broker_redirect_url = "https://software.joyinfotech.co.in:3131/";
// export const react_domain = "https://software.joyinfotech.co.in/";
// export const panel_name = "joyinfotech";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections thegreenalgo

// export const base_url = "https://software.thegreenalgo.com:3132/";
// export const broker_url = "https://software.thegreenalgo.com:3132/";
// export const broker_redirect_url = "https://software.thegreenalgo.com:3132/";
// export const react_domain = "https://software.thegreenalgo.com/";
// export const panel_name = "thegreenalgo";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections ambanialgo

// export const base_url = "https://software.ambanialgo.com:3133/";
// export const broker_url = "https://software.ambanialgo.com:3133/";
// export const broker_redirect_url = "https://software.ambanialgo.com:3133/";
// export const react_domain = "https://software.ambanialgo.com/";
// export const panel_name = "ambanialgo";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections trustalgo

// export const base_url = "https://software.trustalgo.net:3134/";
// export const broker_url = "https://software.trustalgo.net:3134/";
// export const broker_redirect_url = "https://software.trustalgo.net:3134/";
// export const react_domain = "https://software.trustalgo.net/";
// export const panel_name = "trustalgo";
// export const broker_signal_url = "https://brokersignal.trustalgo.net/broker-signals";

// Live Connections indexinfotech

// export const base_url = "https://software.indexinfotech.in:3135/";
// export const broker_url = "https://software.indexinfotech.in:3135/";
// export const broker_redirect_url = "https://software.indexinfotech.in:3135/";
// export const react_domain = "https://software.indexinfotech.in/";
// export const panel_name = "indexinfotech";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections theskylarkresearch

// export const base_url = "https://software.theskylarkresearch.com:3136/";
// export const broker_url = "https://software.theskylarkresearch.com:3136/";
// export const broker_redirect_url = "https://software.theskylarkresearch.com:3136/";
// export const react_domain = "https://software.theskylarkresearch.com/";
// export const panel_name = "theskylarkresearch";
// export const broker_signal_url = "https://brokersignal.theskylarkresearch.com/broker-signals";

// Live Connections meghasoftech

// export const base_url = "https://software.meghasoftech.com:3137/";
// export const broker_url = "https://software.meghasoftech.com:3137/";
// export const broker_redirect_url = "https://software.meghasoftech.com:3137/";
// export const react_domain = "https://software.meghasoftech.com/";
// export const panel_name = "meghasoftech";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections skylineitsolution

// export const base_url = "https://software.skylineitsolution.net:3138/";
// export const broker_url = "https://software.skylineitsolution.net:3138/";
// export const broker_redirect_url = "https://software.skylineitsolution.net:3138/";
// export const react_domain = "https://software.skylineitsolution.net/";
// export const panel_name = "skylineitsolution";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections traditionaltechnology

// export const base_url = "https://software.traditionaltechnology.in:3139/";
// export const broker_url = "https://software.traditionaltechnology.in:3139/";
// export const broker_redirect_url = "https://software.traditionaltechnology.in:3139/";
// export const react_domain = "https://software.traditionaltechnology.in/";
// export const panel_name = "traditionaltechnology";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections algovision

// export const base_url = "https://software.algovision.in:3140/";
// export const broker_url = "https://software.algovision.in:3140/";
// export const broker_redirect_url = "https://software.algovision.in:3140/";
// export const react_domain = "https://software.algovision.in/";
// export const panel_name = "algovision";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections infinitetechsolution

// export const base_url = "https://software.infinitetechsolution.in:3141/";
// export const broker_url = "https://software.infinitetechsolution.in:3141/";
// export const broker_redirect_url = "https://software.infinitetechsolution.in:3141/";
// export const react_domain = "https://software.infinitetechsolution.in/";
// export const panel_name = "infinitetechsolution";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections fintechmechanics

// export const base_url = "https://software.fintechmechanics.com:3142/";
// export const broker_url = "https://software.fintechmechanics.com:3142/";
// export const broker_redirect_url = "https://software.fintechmechanics.com:3142/";
// export const react_domain = "https://software.fintechmechanics.com/";
// export const panel_name = "fintechmechanics";
// export const broker_signal_url = "https://brokersignal.fintechmechanics.com/broker-signals";

// Live Connections robolifetechnology

// export const base_url = "https://software.robolifetechnology.com:3143/";
// export const broker_url = "https://software.robolifetechnology.com:3143/";
// export const broker_redirect_url = "https://software.robolifetechnology.com:3143/";
// export const react_domain = "https://software.robolifetechnology.com/";
// export const panel_name = "robolifetechnology";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections techvisiontechnology

// export const base_url = "https://software.techvisiontechnology.com:3144/";
// export const broker_url = "https://software.techvisiontechnology.com:3144/";
// export const broker_redirect_url = "https://software.techvisiontechnology.com:3144/";
// export const react_domain = "https://software.techvisiontechnology.com/";
// export const panel_name = "techvisiontechnology";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections tradeterminalstation

// export const base_url = "https://software.tradeterminalstation.com:3145/";
// export const broker_url = "https://software.tradeterminalstation.com:3145/";
// export const broker_redirect_url = "https://software.tradeterminalstation.com:3145/";
// export const react_domain = "https://software.tradeterminalstation.com/";
// export const panel_name = "tradeterminalstation";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections universalalgo

// export const base_url = "https://software.universalalgo.com:3148/";
// export const broker_url = "https://software.universalalgo.com:3148/";
// export const broker_redirect_url = "https://software.universalalgo.com:3148/";
// export const react_domain = "https://software.universalalgo.com/";
// export const panel_name = "universalalgo";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections unitechalgo

// export const base_url = "https://software.unitechalgo.com:3149/";
// export const broker_url = "https://software.unitechalgo.com:3149/";
// export const broker_redirect_url = "https://software.unitechalgo.com:3149/";
// export const react_domain = "https://software.unitechalgo.com/";
// export const panel_name = "unitechalgo";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections thegrowtechalgosoftware

// export const base_url = "https://software.thegrowtechalgosoftware.com:3150/";
// export const broker_url = "https://software.thegrowtechalgosoftware.com:3150/";
// export const broker_redirect_url = "https://software.thegrowtechalgosoftware.com:3150/";
// export const react_domain = "https://software.thegrowtechalgosoftware.com/";
// export const panel_name = "thegrowtechalgosoftware";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";

// Live Connections smartindiatechnology

// export const base_url = "https://software.smartindiatechnology.com:3151/";
// export const broker_url = "https://software.smartindiatechnology.com:3151/";
// export const broker_redirect_url = "https://software.smartindiatechnology.com:3151/";
// export const react_domain = "https://software.smartindiatechnology.com/";
// export const panel_name = "smartindiatechnology";
// export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";